const work = [
    {title:'Paytm Insider',image:"https://i.ibb.co/5n9qLXf/insider.png",link:'https://insider.in',tag:'Work',desc:"Originally Insider.in, one of India's major events & movie ticketing services. Included 3 consumer and 3 business products. 11M+ MAUs."},
    {title:'Cobalt',image:"https://i.ibb.co/Kswj2M1/cobalt.png",link:'https://cobalt.neerajnathany.com', tag:'Personal',desc:"The complete personal wardrobe manager. View, sort, tag, filter, pick & group all your clothes, footwear & accessories."},
    {title:'Acronym',image:"https://i.ibb.co/tJS9YdX/acronym.png",link:'https://acronym.neerajnathany.com',tag:'Personal',desc:"A simple, neat, no-frills news service. Search, find & read articles across 70+ sections & categories. Sourced from The Guardian."},
    {title:'Epoch',image:"https://i.ibb.co/cL7CFnh/Web-1920-4.png", link:'https://epoch.neerajnathany.com', tag:'Personal', desc:"The perfect communication client. Newsletters, receipts, invoices, forwards and OTPs were never meant to be emails."},
    {title:'Polymer',image:"https://i.ibb.co/XJhzddN/polymer.png",link:'https://polymer.neerajnathany.com',tag:'Personal',desc:"Your own relationships manager. An organized representation of your friends based on intuitive personal metrics."},
    {title:'Salted Caramel',image:"https://i.ibb.co/SXkS0LW/image.png",link:'https://saltedcaramel.in',tag:'Work',desc:"Celebrate your biggest events or the most intimate occasions with the choicest selection of gift hampers & merchandise"}
]

const edu = [
    {title: 'ISB Hyderabad',time:'Apr 2018 - Apr 2019', branch:'PGPM - Finance, Mktg', grade:'3.7', image:"https://i.ibb.co/vPyxHRK/image.png"},
    {title: 'BITS Pilani, Goa',time:'Jun 2010 - Jun 2014', branch:'B.E.(Hons) - EEE', grade:'8.1', image:"https://i.ibb.co/sjyx8D6/image.png"},
    {title: "St. Xaviers, Mumbai",time:'Apr 2008 - Apr 2010', branch:'HSC - Science', image:"https://i.ibb.co/3cTGQdz/image.png"},
]

const exp = [
    {title: 'BCG',time:'Feb 2022 - Present', branch:'Management Consulting', image:"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/BCG_Corporate_Logo.svg/1200px-BCG_Corporate_Logo.svg.png"},
    {title: 'JP Morgan Chase',time:'Apr 2019 - Feb 2022', branch:'QR, Investment Banking', image:"https://i.ibb.co/qNC8Cx3/image.png"},
    {title: 'Insider.in',time:'Aug 2015 - Apr 2018', branch:'Head of Product', image:"https://i.ibb.co/F6xSTQM/image.png"},
    {title: "Oracle Corporation",time:'Jun 2014 - Aug 2015', branch:'Lead Apps Developer', image:"https://i.ibb.co/K7sbP3z/image.png"},
    {title: 'Deloitte TT India',time:'Jan 2014 - Jun 2014', branch:'ERS Consulting Intern', image:"https://i.ibb.co/Sd82Htq/image.png"}
]

export {work, edu, exp};