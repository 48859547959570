import React, { Component } from 'react';
import {work, edu, exp} from './constants.js';
import Work from './components/Work';
import Card from './components/Card';

class App extends Component {
    state = {  }
    render() { 
        return ( 
            <main className="main">
                <div className="main-view">
                    <div className="hero">
                        <h1 className="hero-name">Neeraj Nathany</h1>
                        <div className="link">
                            <a className="link-each" href="https://www.linkedin.com/in/neerajnathany/" target="_blank" rel="noreferrer noopener">
                                <div className="link-tile"><img src="https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-free/128/social-linkedin-circle-512.png" alt="Linkedin"/></div>
                            </a>
                            <a className="link-each" href="https://github.com/neerajnathany" target="_blank" rel="noreferrer noopener">
                                <div className="link-tile"><img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" alt="Github"/></div>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="section edu">
                            <h4 className="section-name"><span>Education</span></h4>
                            <div>
                                {edu.map(e => {
                                    return <Card title={e.title} branch={e.branch} time={e.time} grade={e.grade} img={e.image}/>
                                })}
                            </div>
                        </div>
                        <div className="section exp">
                            <h4 className="section-name"><span>Experience</span></h4>
                            <div>
                                {exp.map(e => {
                                    return <Card title={e.title} branch={e.branch} time={e.time} grade={e.grade} img={e.image}/>
                                })}
                            </div>
                        </div>
                        <div className="section work">
                            <h4 className="section-name"><span>Products</span></h4>
                            <div>
                                {work.map(e => {
                                    return <Work title={e.title} desc={e.desc} tag={e.tag} link={e.link} img={e.image}/>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
         );
    }
}
 

export default App;