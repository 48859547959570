import React, { Component } from 'react';

class Work extends Component {
    state = {  }
    render() { 
        return ( 
            <a className="work-item" href={this.props.link} target="_blank" rel="noreferrer noopener">
                <div className="work-tile">
                    <img src={this.props.img} alt={this.props.title}/>
                    <span className="work-tag">{this.props.tag}</span>
                </div>
                <div className="work-detail">
                    <h6 className="work-title">{this.props.title}</h6>
                    <p className="work-desc">{this.props.desc}</p>
                </div>
            </a>
         );
    }
}
 
export default Work;