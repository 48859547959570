import React, { Component } from 'react';

class Card extends Component {
    state = {  }
    render() { 
        return ( 
        <div className="card">
            <div className="card-tile">
                <img src={this.props.img} alt={this.props.title}/>
            </div>
            <div className="card-detail">
                <h6 className="card-title">{this.props.title}</h6>
                <span>{this.props.branch}{this.props.grade ? ', '+this.props.grade : null}</span>
                <span>{this.props.time}</span>
            </div>

        </div> );
    }
}
 
export default Card;